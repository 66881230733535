<template>
  <a-modal v-model="isShow" :title=" '代付' " @ok="handleOkFunc" :confirmLoading="confirmLoading">
    <a-form-model ref="infoFormModel" :model="saveObject" :label-col="{span: 6}" :wrapper-col="{span: 15}" :rules="rules">
      <a-form-model-item label="可代付金额：" prop="inputAmount">
        <a-input v-model="saveObject.inputAmount" disabled />
        <a-input v-model="saveObject.id" type="hidden" />
      </a-form-model-item>
      <!-- 分账类型下拉选择框 -->
       <a-form-model-item label="分账类型：" prop="paymentByType">
        <a-select v-model="saveObject.paymentByType" placeholder="请选择分账类型">
          <a-select-option :value="1">按照商户分账</a-select-option>
          <a-select-option :value="2">按照应用分账</a-select-option>
          <a-select-option :value="3">按照汇付商户分账</a-select-option>
          <a-select-option :value="4">按照汇付应用</a-select-option>
        </a-select>

       </a-form-model-item>
         <a-text style="font-size: 11px;color: red;position: relative;left:120px;bottom:20px">按照汇付商户或汇付应用分账,需配置应用与汇付商户、汇付应用的关系</a-text>
        <a-form-model-item label="代付金额：" prop="amount">
        <a-input v-model="saveObject.amount" placeholder="请输入"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { API_URL_DIVISION_ADAPAYMEMBER, API_URL_MCH_BALANCE_URL, doPayment, req } from '@/api/manage'
export default {
  props: {
    callbackFunc: { type: Function }
  },
  data () {
    return {
      confirmLoading: false, // 显示确定按钮loading图标
      isShow: false, // 是否显示弹层/抽屉
      saveObject: {
        appId: '',
        amount: 0,
        inputAmount: 0,
        adapayAccountId: '',
        paymentByType: 1
      }, // 数据对象
      id: null, // 更新对象ID
      rules: {
        amount: [{ trigger: 'blur',
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入代付金额'))
            } else if (Number(value) > Number(this.saveObject.inputAmount)) {
                  callback(new Error('代付金额大于可代付金额'))
            }
            callback()
        } }]
      }
    }
  },
  created () {
  },

  methods: {
    show (id) {
      this.saveObject.adapayAccountId = id
      this.saveObject.amount = ''
      // 根据id查询
      req.getById(API_URL_DIVISION_ADAPAYMEMBER, id).then(res => {
        // 根据appid查商户可代付金额
        req.getById(API_URL_MCH_BALANCE_URL, res.appId).then(res => {
          this.saveObject.inputAmount = res.acct_balance || 0
          this.isShow = true
        })
      })
    },
    handleOkFunc: function () { // 点击【确认】按钮事件
      const that = this
      this.$refs.infoFormModel.validate(valid => {
              if (valid) { // 验证通过
                doPayment(that.saveObject).then(apiRes => {
              if (apiRes.state === 1) {
                that.$message.success('受理成功')
                    that.isShow = false
                    that.callbackFunc() // 刷新列表
              } else {
                that.$infoBox.modalError('代付失败', <div><div>错误码：{ apiRes.errCode}</div>
                  <div>错误信息：{ apiRes.errMsg}</div></div>)
              }
            })
              }
            })
    }
  }
}
</script>
