<template>
  <a-modal v-model="isShow" :title=" isAdd ? '新增汇付收款账户' : '提现' " @ok="handleOkFunc" :confirmLoading="confirmLoading">
    <a-form-model ref="infoFormModel" :model="saveObject" :label-col="{span: 6}" :wrapper-col="{span: 15}" :rules="rules">
      <a-form-model-item label="可提现金额：" prop="inputAmount">
        <a-input v-model="saveObject.inputAmount" disabled />
        <a-input v-model="saveObject.id" type="hidden" />
      </a-form-model-item>
        <a-form-model-item label="提现金额：" prop="amount">
        <a-input v-model="saveObject.amount" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="到账方式：" prop="channelExtra">
            <a-select v-model="saveObject.channelExtra" placeholder="到账方式" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="D0">D0</a-select-option>
                <a-select-option value="D1">D1</a-select-option>
                <a-select-option value="T1">T1</a-select-option>
              </a-select>
          </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { API_URL_DIVISION_ADAPAYMEMBER, doTransfer, req } from '@/api/manage'
export default {
  props: {
    callbackFunc: { type: Function }
  },
  data () {
    return {
      confirmLoading: false, // 显示确定按钮loading图标
      isAdd: true, // 新增 or 修改页面标识
      isShow: false, // 是否显示弹层/抽屉
      saveObject: { autoDivisionFlag: 0, inputAmount: 0 ,channelExtra:"D0"}, // 数据对象
      id: null, // 更新对象ID
      rules: {
        amount: [{ trigger: 'blur',
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入提现金额'))
            } else if (value > this.saveObject.inputAmount) {
                  callback(new Error('提现金额大于可提现金额'))
            }
            callback()
        } }]
      }
    }
  },
  created () {
  },

  methods: {
    show: function (id, inputAmount) { // 弹层打开事件
      this.isAdd = !id
    
      this.saveObject = { autoDivisionFlag: 0 } // 数据清空
      this.confirmLoading = false // 关闭loading
      if (this.$refs.infoFormModel !== undefined) {
        this.$refs.infoFormModel.resetFields()
      }
      const that = this
      if (!this.isAdd) { // 修改信息 延迟展示弹层
        that.id = id
        req.getById(API_URL_DIVISION_ADAPAYMEMBER, id).then(res => {
          that.saveObject = res


          that.saveObject.inputAmount = inputAmount
          that.isShow = true
        })
      } else {
        that.isShow = true // 立马展示弹层信息
      }
    },
    handleOkFunc: function () { // 点击【确认】按钮事件
      const that = this

      this.$refs.infoFormModel.validate(valid => {
              if (valid) { // 验证通过
              doTransfer(that.saveObject).then(apiRes => {
              if (apiRes.state === 2) {
                that.$message.success('提现成功')
                    that.isShow = false
                    that.callbackFunc() // 刷新列表
              } else if (apiRes.state === 1) {
                that.$message.success('提现申请提交成功')
                    that.isShow = false
                    that.callbackFunc() // 刷新列表
              } else {
                that.$infoBox.modalError('提现失败', <div><div>错误码：{ apiRes.errCode}</div>
                  <div>错误信息：{ apiRes.errMsg}</div></div>)
              }
            }).catch(() => {
              that.randomOrderNo() // 刷新订单号
            })
              }
            })
    }
  }
}
</script>
