<template>
  <page-header-wrapper>
    <a-card>
      <div v-if="$access('ENT_DIVISION_ADAPAY_MEMBER_LIST')" class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div
              class="table-layer"
          >
            <jeepay-text-up :placeholder="'商户号'" :msg="searchData.mchNo" v-model="searchData.mchNo" />
            <a-form-item label="" class="table-head-layout" :wrapper-col="{span: 16}">
              <a-select v-model="searchData.appId" placeholder="选择应用" show-search  optionFilterProp="label" :allowClear="true">
                <!-- <a-select-option key="" >全部应用</a-select-option> -->
                <a-select-option v-for="(item) in mchAppList" :key="item.appId"  :value="item.appId"  :label="item.appName">{{ item.appName }}</a-select-option>
              </a-select>
            </a-form-item>
            <jeepay-text-up :placeholder="'昵称'" :msg="searchData.nickname" v-model="searchData.nickname" />
            <jeepay-text-up :placeholder="'电话'" :msg="searchData.telNo" v-model="searchData.telNo" />
            <jeepay-text-up :placeholder="'卡号'" :msg="searchData.cardId" v-model="searchData.cardId" />
            <jeepay-text-up :placeholder="'卡名称'" :msg="searchData.cardName" v-model="searchData.cardName" />
            <jeepay-text-up :placeholder="'证件号'" :msg="searchData.certId" v-model="searchData.certId" />
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchFunc" icon="search" :loading="btnLoading">查询</a-button>
              <a-button style="margin-left: 8px;" @click="() => this.searchData = {}" icon="reload">重置</a-button>
            </span>
          </div>
        </a-form>
          <!--统计信息-->
        <div style="margin-bottom:20px">
          <a-button type="primary"   @click="showStatisticsClick">
            <a-icon type="bar-chart" /> {{ showStatistics ? '关闭统计' : '显示统计' }}
            </a-button>
          <div class="statistics-view" v-if="showStatistics" :searchData="searchData">
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>可用余额</span>
                <span class="amount-view">{{adapayAccount.avl_balance | curreny}}<span class="text-view">元</span></span>
              </div>
            </div>
            <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>账号余额</span>
                <span class="amount-black-view">{{adapayAccount.acct_balance | curreny}}<span class="text-view">元</span></span>
                <!-- <span style="margin-top: 10px;">0笔<a-button type="link" style="padding:5px"
                    @click="detail">明细</a-button></span> -->
              </div>
            </div>
           <!-- <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>手续费金额</span>
                <span class="amount-black-view"><span class="text-view">元</span></span>
              </div>
            </div>-->
            <!-- <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>退款订单</span>
                <span class="amount-h-view">0.00<span class="text-view">元</span></span>
                <span style="margin-top: 10px;">0笔</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 列表渲染 -->
      <JeepayTable
          ref="infoTable"
          :initData="true"
          :reqTableDataFunc="reqTableDataFunc"
          :tableColumns="tableColumns"
          :searchData="searchData"
          @btnLoadClose="btnLoading=false"
          :scrollX="500"
          rowKey="receiverGroupId"
      >
        <template slot="accTypeSlot" slot-scope="{record}">
         <a-tag color="blue" v-if="record.accType == 0">个人私户</a-tag>
         <a-tag color="blue" v-if="record.accType == 1">默认账号</a-tag>
         <a-tag color="blue" v-if="record.accType == 2">企业公户</a-tag>
        </template>
        <template slot="appName" slot-scope="{record}">
          <span >{{ getAppName(record.appId) }}</span>
        </template>
      <template slot="notifySlot" slot-scope="{record}">
         <a-tag color="blue" v-if="record.binding == 0 && record.acountType==0">未绑定</a-tag>
         <a-tag color="blue" v-if="record.binding == 1">已绑定</a-tag>
        </template>
        <template slot="opSlot" slot-scope="{record}">  <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button type="link" v-if="$access('ENT_DIVISION_ADAPAYMEMBER_EDIT')" @click="editFunc(record.id)">修改</a-button>
            <a-button type="link" v-if="$access('ENT_DIVISION_ADAPAYMEMBER_EDIT')"  @click="withdRawalFunc(record.id,record.avlBalance)">提现</a-button>
            <a-button type="link" v-if="$access('ENT_DIVISION_ADAPAYMEMBER_EDIT')"  @click="paymentFunc(record.id)">代付</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>
    <!-- 新增 / 修改 页面组件  -->
    <InfoAddOrEdit ref="infoAddOrEdit" :callbackFunc="searchFunc" />
    <Withdrawal ref="withdrawal" :callbackFunc="searchFunc"/>
    <Payment ref="payment" :callbackFunc="searchFunc"/>
  </page-header-wrapper>
</template>
<script>
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { API_URL_DIVISION_ADAPAYMEMBER, API_URL_DIVISION_ADAPAYMEMBER_BANGDING, API_URL_ADAPAYACCOUNT_ORDER_COUNT, API_URL_DIVISION_ADAPAYMEMBER_JIEBANG, API_URL_MCH_APP, req } from '@/api/manage'
import InfoAddOrEdit from './AddOrEdit'
import Withdrawal from './withdrawal'
import Payment from './payment'
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import appId from '@/views/Utils/appId'
// eslint-disable-next-line no-unused-vars
const tableColumns = [
  { key: 'cardName', dataIndex: 'cardName', title: '开户名称', width: '100px' },
  { key: 'appName', title: '应用名称', width: '100px',scopedSlots: { customRender: 'appName' } },
  { key: 'cardId', dataIndex: 'cardId', title: '开户卡号', width: '185px' },
  { key: 'telNo', dataIndex: 'telNo', title: '电话', width: '120px' },
  { key: 'certId', dataIndex: 'certId', title: '证件号', width: '180px' },
  { key: 'accType', title: '账户类型', width: '100px', scopedSlots: { customRender: 'accTypeSlot' } },
  { key: 'avlBalance', dataIndex: 'avlBalance', title: '可用余额', width: '100px',customRender : (amount) => {return amount ? parseFloat(amount).toFixed(2) : "0.00"} },
  { key: 'acctBalance', dataIndex: 'acctBalance', title: '账户余额', width: '100px',customRender : (amount) => {return amount ? parseFloat(amount).toFixed(2) : "0.00"} },
  { key: 'frzBalance', dataIndex: 'frzBalance', title: '冻结金额', width: '100px',customRender : (amount) => {return amount ? parseFloat(amount).toFixed(2) : "0.00"} },
  { key: 'divisionProfit', dataIndex: 'divisionProfit', title: '分账比例', width: '100px' },
  { key: 'binding', title: '是否绑定', width: '100px', scopedSlots: { customRender: 'notifySlot' } },
  { key: 'createdAt', dataIndex: 'createdAt', title: '创建时间', width: '170px' },
  { key: 'op', title: '操作', width: '250px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]

export default {
  name: 'RolePage',
  mixins:[appId],
  components: { JeepayTable, JeepayTableColumns, InfoAddOrEdit, Withdrawal, JeepayTextUp, Payment },
  data () {
    return {
      showStatistics: false,
      tableColumns: tableColumns,
      searchData: {},
      btnLoading: false,
      adapayAccount: {},
      mchAppList: [] // 商户app列表
    }
  },
  mounted () {
    const that = this // 提前保留this
    // 请求接口，获取所有的appid，只有此处进行pageSize=-1传参
    req.list(API_URL_MCH_APP, { pageSize: -1 }).then(res => {
      that.mchAppList = res.records;
    })
    this.reqPayOrderCount()
  },
  methods: {
      showStatisticsClick () {
        this.showStatistics = !this.showStatistics
      },
    // 请求table接口数据
    reqTableDataFunc: (params) => {
      return req.list(API_URL_DIVISION_ADAPAYMEMBER, params)
    },

    searchFunc: function () { // 点击【查询】按钮点击事件
      this.btnLoading = true // 打开查询按钮上的loading
      this.$refs.infoTable.refTable(true)
      this.reqPayOrderCount()
    },

    addFunc: function () { // 业务通用【新增】 函数
      this.$refs.infoAddOrEdit.show()
    },

    editFunc: function (id) { // 业务通用【修改】 函数
      this.$refs.infoAddOrEdit.show(id)
    },
    withdRawalFunc: function (id, inputAmount) { // 业务通用【提现】 函数
      this.$refs.withdrawal.show(id, inputAmount)
    },
    // 代付
    paymentFunc: function (id) {
      this.$refs.payment.show(id)
    },
 // 请求统计接口数据
    reqPayOrderCount () {
      var params = {
        nickname: this.searchData.nickname,
        appId: this.searchData.appId,
        telNo: this.searchData.telNo,
        cardId: this.searchData.cardId,
        cardName: this.searchData.cardName,
        certId: this.searchData.certId
      }
      req.list(API_URL_ADAPAYACCOUNT_ORDER_COUNT, params).then(res => {
        this.adapayAccount = res
      })
    },
    delFunc: function (id) { // 业务通用【删除】 函数
      const that = this
      this.$infoBox.confirmDanger('确认删除？', '', () => {
        // 需要【按钮】loading 请返回 promise对象， 不需要请直接返回null
        return req.delById(API_URL_DIVISION_ADAPAYMEMBER, id).then(res => {
          that.$message.success('删除成功！')
          that.$refs.infoTable.refTable(false)
        })
      })
    },
    bdFunc: function (id) { // 业务通用【删除】 函数
      const that = this
      this.$infoBox.confirmDanger('确认绑定吗？', '', () => {
        // 需要【按钮】loading 请返回 promise对象， 不需要请直接返回null
        return req.getById(API_URL_DIVISION_ADAPAYMEMBER_BANGDING, id).then(res => {
          that.$message.success('绑定成功！')
          that.$refs.infoTable.refTable(false)
        })
      })
    },
    jbFunc: function (id) { // 业务通用【删除】 函数
      const that = this
      this.$infoBox.confirmDanger('确认解绑吗？', '', () => {
        // 需要【按钮】loading 请返回 promise对象， 不需要请直接返回null
        return req.getById(API_URL_DIVISION_ADAPAYMEMBER_JIEBANG, id).then(res => {
          that.$message.success('绑定成功！')
          that.$refs.infoTable.refTable(false)
        })
      })
    }
  }
}
</script>
<style scoped lang="less">
.statistics-view {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  margin-top: 20px;
  padding: 30px 0px 30px 0px;

}

.statistics-item-view {
  flex-direction: column;
  display: flex;
  text-align: start;
  align-items: center;
}

.amount-view {
  font-size: 20px;
  color: #5485f8;
  font-weight: bold;
  margin-top: 10px;
}

.amount-black-view {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-top: 10px;
}

.amount-h-view {
  font-size: 20px;
  color: #dbaf0e;
  font-weight: bold;
  margin-top: 10px;
}

.text-view {
  font-size: 14px;
  font-weight: normal;
}

.mx-text {
  font-size: 14px;
  font-weight: normal;
  color: #55a2ff;
  padding-left: 10px;
}
</style>
