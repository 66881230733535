export default {
    methods: {
        getAppName(appId) {
            console.log(this.mchAppList, "mchAppList") 
            var appName = ''
            this.mchAppList.map(item => {
                if (item.appId === appId) {
                    appName = item.appName;
                }
            })
            return appName ? appName : ""
        }
    }
}